// components
import Seo from 'src/components/Seo';
// hooks
import useDiscoveryPhaseMetaData from 'src/cms/data/discoveryPhase/useDiscoveryPhaseMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const discoveryPhaseMetaData = useDiscoveryPhaseMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={discoveryPhaseMetaData?.title}
      description={discoveryPhaseMetaData?.description}
      schema={discoveryPhaseMetaData?.schema}
    />
  );
};

export { default } from './DiscoveryPhase';
