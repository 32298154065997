// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// sections
import Included from './sections/Included/Included';
import Pack from './sections/Pack';
// hooks
import useDiscoveryPhaseIntroData from 'src/cms/data/discoveryPhase/useDiscoveryPhaseIntroData';
import usePacksData from 'src/cms/data/discoveryPhase/usePacksData';
import { FC } from 'react';
import { PageProps } from 'gatsby';

const DiscoveryPhase: FC<PageProps> = () => {
  // hooks
  const { title, description, mobileTitle } = useDiscoveryPhaseIntroData();
  const { packList } = usePacksData();

  return (
    <PageLayout>
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='discoveryPhaseDrawing'
      />
      <Included />
      {packList.map((pack, index) => (
        <Pack key={index} {...pack} />
      ))}
    </PageLayout>
  );
};

export default DiscoveryPhase;
